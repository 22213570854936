var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',[_c('b-card-header',{staticStyle:{"padding-top":"0px","padding-right":"0px"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-card-title',[_c('h4',[_c('b',{staticStyle:{"color":"cornflowerblue"}},[_vm._v("Daftar Produk ")])])])],1),_c('b-nav',{attrs:{"align":_vm.selected}},[_c('b-nav-item',[_c('router-link',{attrs:{"to":"/admin/add_product"}},[_c('b-button',{attrs:{"variant":"success"}},[_c('feather-icon',{staticStyle:{"color":"white"},attrs:{"icon":"PlusSquareIcon"}}),_c('span',{staticClass:"align-right"},[_c('b',{staticStyle:{"color":"white"}},[_vm._v(" Tambah")])])],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
          enabled: false,
          externalQuery: _vm.searchTerm,
        },"select-options":{
          enabled: false,
          selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
          disableSelectInfo: false, // disable the select info panel on top
          selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        },"pagination-options":{
          enabled: true,
          perPage: _vm.pageLength,
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',{staticClass:"btn-icon text-center"},[_c('b-nav',[_c('b-nav-item',[_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"gradient-success"},on:{"click":function($event){return _vm.$router.push({
                      name: 'edit_product',
                      params: { id: props.row.id },
                    })}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1)],1),_c('b-nav-item',[_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"gradient-danger"},on:{"click":function($event){return _vm.deleteData(props.row.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['10', '20', '50']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":_vm.pagnation_posisition,"prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }